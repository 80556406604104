import { Result, Button } from "antd"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import styled from "styled-components"

const Container = styled.div`
  font-size: 20px;
  line-height: 27px;
`

const PageNotFound = () => {
  const router = useRouter()
  const { t } = useTranslation()
  return (
    <Container>
      <Result
        status="404"
        title="404"
        subTitle={
          <Container>{t`Sorry, the page you visited does not exist.`}</Container>
        }
        extra={
          <Button
            onClick={() => {
              router.push("/")
            }}
            type="primary"
          >
            {t("Back to top")}
          </Button>
        }
      />
    </Container>
  )
}

export default PageNotFound
